<template>
    <div class="project-container">
        <div class="common-first-part">
            <div>
                <p>项目合作</p>
            </div>
            <img :src="require('@/assets/images/index/project-2.png')" alt="">
        </div>
        <div class="project-information">
            <p class="top-title">高校合作伙伴与专家作者招募</p>
            <div class="infos-lists" style="padding-bottom: 24px;">
                <div>清华社诚挚邀请高校学前教育专业院校参与以下学前教育专业资源建设：</div>
                <p>（1）学前教育专业系列新形态教材建设。</p>
                <p>（2）学前教育教学资源库建设。</p>
                <p>（3）数字课程出版建设。</p>
                <p>（4）学前教育金课建设。</p>
                <p>（5）学前教育专业虚拟仿真实训系统建设。</p>
            </div>
            <div class="infos-lists">
                <div>合作支持：</div>
                <p>（1）教材与电子出版成果物可用于职称评定或教学成果认定。 </p>
                <p>（2）优秀出版成果清华社将推荐参与国家新闻出版署“数字出版精品项目”以及“中华优秀出版物奖”等的评选。 </p>
                <p>（3）数字课程（教材）相关的教研教改成果论文、信息化教学设计、信息化教学案例等，可以单独另行在我社国家级电子期刊推荐发表论文。 </p>
                <p>（4）相关教师或课程团队，我社将择优邀请参与教育部“协同育人”产学合作项目、“国家出版基金项目”以及相关各级各类科研课题等的申报和建设工作。</p>
                <p>（5）参与专家作者将纳入清华社教育领域融合出版知识挖掘与服务重点实验室学前教育研究组，颁发聘书，给予专家待遇。</p>
            </div>

            <div class="contact">
                <p>联系人：薛老师   </p>
                <p>联系方式：010-83470435  </p>
                <p>邮箱：xuesh@tup.tsinghua.edu.cn</p>
            </div>
        </div>
        <div class="project-information">
            <p class="top-title">区域代理商招募</p>
            <p class="second-title">清华社诚挚招募学前教育专业产品代理商。参与各项学前教育专业产品销售。</p>
            <div class="contact">
                <p>联系人：屈老师   </p>
                <p>联系方式：010-83470453  </p>
                <p>邮箱：qumy@tup.tsinghua.edu.cn</p>
            </div>
        </div>
        <div class="project-information">
            <p class="top-title">教具等硬件商合作招募</p>
            <p class="second-title">清华社诚挚招募学前教育方向教具、教学设备等硬件合作商，欢迎与我们联系。</p>
            <div class="contact">
                <p>联系人：王老师   </p>
                <p>联系方式：010-83470456  </p>
                <p>邮箱：wangying@tup.tsinghua.edu.cn</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.project-container {
    .project-information {
        width: 790px;
        margin: auto;
        border-bottom: 1px solid #4A90E2;
        box-sizing: border-box;
        padding-bottom: 47px;
        padding-top: 60px;
        &:first-child {
            padding-top: 50px;
        }
        &:last-child {
            margin-bottom: 80px;
        }
        .top-title {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 33px;
            margin-bottom: 23px;
            text-align: center;
        }
        .second-title {
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 26px;
            text-align: center;
        }
        .infos-lists {
            padding: 0 95px;
            box-sizing: border-box;
            &>div {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                margin-bottom: 10px;
            }
            &>p {
                font-size: 14px;
                color: #333333;
            }
        }
        .contact {
            margin-top: 60px;
            font-size: 16px;
            font-weight: 400;
            color: #0768AB;
            line-height: 24px;
            padding: 0 95px;
            box-sizing: border-box;
        }
    }
}
</style>